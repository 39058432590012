import React from "react";

import "./Content.css";

type Props = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
};

export default function Content({ children, className, ...attrs }: Props) {
  return (
    <div className={`textContent ${className}`.trim()} {...attrs}>
      {children}
    </div>
  );
}
