import { useEffect, useState } from "react";

type Props = {
  loading: boolean;
  delay?: number;
};

export const useDebouncedLoading = ({ loading, delay = 300 }: Props) => {
  const [debouncedLoading, setDebouncedLoading] = useState(false);

  useEffect(() => {
    if (loading === false) {
      setDebouncedLoading(false);
      return;
    }

    const timeout = window.setTimeout(() => {
      setDebouncedLoading(true);
    }, delay);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [loading, delay]);

  return debouncedLoading;
};
