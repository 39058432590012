import i18n from "i18next";

export default function getLocationByNumber(placeNumber) {
  switch (placeNumber) {
    case 0:
      return i18n.t("location.dinoville");
    case 1:
      return i18n.t("location.caverneirma");
    case 2:
      return i18n.t("location.clairiere");
    case 3:
      return i18n.t("location.dinoplage");
    case 4:
      return i18n.t("location.barrage");
    case 5:
      return i18n.t("location.falaise");
    case 6:
      return i18n.t("location.montdino");
    case 7:
      return i18n.t("location.porte");
    case 8:
      return i18n.t("location.gredins");
    case 9:
      return i18n.t("location.foret");
    case 10:
      return i18n.t("location.temple");
    case 11:
      return i18n.t("location.port");
    case 12:
      return i18n.t("location.pitie");
    case 13:
      return i18n.t("location.ruines");
    case 14:
      return i18n.t("location.credit");
    case 15:
      return i18n.t("location.bazar");
    case 16:
      return i18n.t("location.marais");
    case 17:
      return i18n.t("location.jungle");
    case 18:
      return i18n.t("location.bordeciel");
    case 19:
      return i18n.t("location.source");
    case 20:
      return i18n.t("location.anomalie");
    case 21:
      return i18n.t("location.hutte");
    case 22:
      return i18n.t("location.toutchaud");
    case 23:
      return i18n.t("location.bastion");
    case 24:
      return i18n.t("location.portroyal");
    case 25:
      return i18n.t("location.suntzu");
    case 26:
      return i18n.t("location.labyrinthe");
    case 27:
      return i18n.t("location.bosquet");
    case 28:
      return i18n.t("location.stiou");
    case 29:
      return i18n.t("location.universite");
    case 30:
      return i18n.t("location.labo");
    case 31:
      return i18n.t("location.puit");
    case 32:
      return i18n.t("location.everouest");
    case 33:
      return i18n.t("location.lacceleste");
    case 34:
      return i18n.t("location.repaire");
    case 35:
      return i18n.t("location.villagefantome");
    case 36:
      return i18n.t("location.dinocropole");
    case 37:
      return i18n.t("location.plaines");
    case 39:
      return i18n.t("location.retour");
    default:
      return i18n.t("location.balez");
  }
}
