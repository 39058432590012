import React from "react";
import "./SectionLight.css";

type Props = {
  title: string | React.ReactNode;
  children: React.ReactNode;
};

export default function SectionLight({ title, children }: Props) {
  return (
    <div className="sectionLight">
      <header className="sectionLight-title">{title}</header>
      {children}
    </div>
  );
}
