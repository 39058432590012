import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { apiUrl } from "..";
import Store from "../utils/Store";
import { Dinoz } from "../types/dinoz";
import { useUserData } from "./userData";
import urlJoin from "url-join";

export const ITEMS_PER_PAGE = 30;

type UserDinozList = {
  loading: boolean;
  list: Dinoz[];
  refreshList: () => Promise<void>;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  total: number;
  // setTotal: (value: number)=>void
  selectedDinozId: string | null;
  setSelectedDinozId: (value: string | null) => void;
};

export const UserDinozListContext = createContext<UserDinozList>(null);

type Props = {
  children: React.ReactElement;
};

function fetchDinozListByPage(page: number) {
  let store = Store.getInstance();
  return axios.get<Dinoz[]>(urlJoin(apiUrl, "account", store.getAccountId(), "dinozList", String(page)));
}

export const UserDinozListProvider = ({ children }: Props) => {
  const { isAuthenticated } = useUserData();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<Dinoz[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  // const [total, setTotal] = useState(0);
  const [selectedDinozId, setSelectedDinozId] = useState<null | string>(null);

  const { numberOfDinoz: total } = useUserData();

  useEffect(() => {
    let ignore = false;
    setLoading(true);
    fetchDinozListByPage(currentPage).then(({ data }) => {
      if (!ignore) {
        setList(data);
        setLoading(false);
      }
    });

    return () => {
      ignore = true;
    };
  }, [currentPage, isAuthenticated]);

  // TODO remove after store remove
  useEffect(() => {
    const store = Store.getInstance();
    store.setSelectedDinoz(selectedDinozId);
  }, [selectedDinozId]);

  function refreshList() {
    setLoading(true);
    return fetchDinozListByPage(currentPage).then(({ data }) => {
      setList(data);
      setLoading(false);
    });
  }

  return (
    <UserDinozListContext.Provider
      value={{
        loading,
        list,
        refreshList,
        currentPage,
        setCurrentPage,
        total,
        // setTotal,
        selectedDinozId,
        setSelectedDinozId,
      }}
    >
      {children}
    </UserDinozListContext.Provider>
  );
};

export const useUserDinozList = () => {
  const { accountId } = useUserData();
  const userDinozListContext = useContext(UserDinozListContext);

  const itemsPerPage = ITEMS_PER_PAGE;
  const totalPages = Math.floor(userDinozListContext.total / itemsPerPage);
  const hasPreviousPage = userDinozListContext.currentPage > 0;
  const hasNextPage = userDinozListContext.currentPage < totalPages;

  function goPreviousPage() {
    if (hasPreviousPage) {
      userDinozListContext.setCurrentPage((currentPage) => currentPage - 1);
    }
  }

  function goNextPage() {
    if (hasNextPage) {
      userDinozListContext.setCurrentPage((currentPage) => currentPage + 1);
    }
  }

  function getDinozListPosition(dinozId) {
    return userDinozListContext.list.findIndex((dinoz) => dinoz.id === dinozId);
  }

  function moveDinozListPosition({
    dinozId,
    moveValue,
  }: {
    dinozId: string;
    moveValue: number;
  }) {
    const currentPage = userDinozListContext.currentPage;
    const currentDinozPosition = getDinozListPosition(dinozId);
    const newDinozPosition = currentDinozPosition - moveValue;
    const direction = moveValue < 0 ? -1 : 1;
    const tempMovedPage = Math.floor(newDinozPosition / itemsPerPage);
    const newPage = Math.min(
      Math.max(currentPage + tempMovedPage, 0),
      totalPages
    );
    const finalMovedPage = newPage - currentPage;

    let requestUrl = `${apiUrl}/account/${accountId}/${dinozId}/moveInList/${direction}`;
    if (Math.abs(moveValue) > 1) {
      requestUrl += `/${Math.abs(moveValue)}`;
    }

    return axios.put(requestUrl).then(() => {
      if (finalMovedPage === 0 || currentDinozPosition === -1) {
        userDinozListContext.refreshList();
        return;
      }
      const movePageMethod = direction === 1 ? goPreviousPage : goNextPage;
      for (let i = Math.abs(finalMovedPage); i > 0; i--) {
        movePageMethod();
      }
    });
  }

  function getDinozById(dinozId: string) {
    return userDinozListContext.list.find((d) => d.id === dinozId);
  }

  function updateDinozIntoList(dinoz: Dinoz) {
    const dinozIndex = getDinozListPosition(dinoz.id);
    if (dinozIndex === -1) {
      return;
    }
    userDinozListContext.list = userDinozListContext.list.map((d) =>
      d.id === dinoz.id ? dinoz : d
    );
  }

  return {
    ...userDinozListContext,
    itemsPerPage,
    totalPages,
    hasPreviousPage,
    hasNextPage,
    goPreviousPage,
    goNextPage,
    getDinozListPosition,
    moveDinozListPosition,
    getDinozById,
    updateDinozIntoList,
  };
};
