import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Clock from "react-live-clock";
import Flags from "./Flags";
import { apiUrl } from "../../index";
import fb_logo from "../../media/game/fb_logo.png";

import etwinImg from "../../media/game/eternaltwin.png";
import {
  Sidebar as BaseSidebar,
  SidebarSection,
  SidebarLink,
  SidebarLinks,
} from "../../components/Sidebar";
import urlJoin from "url-join";

type Props = {
  children: React.ReactNode;
};

export default function Sidebar({ children }: Props) {
  return (
    <BaseSidebar>
      {children}
      <ServerInfos />
      <Flags />
      <a href="https://eternal-twin.net/" target="_blank" rel="noreferrer noopener">
        <img alt="Eternal Twin" className="etwinMiniBanner" src={etwinImg} />
      </a>
        <a href="https://www.facebook.com/profile.php?id=100063497113678" target="_blank" rel="noreferrer noopener">
            <img alt="" className="fb_logo" src={fb_logo}/>
        </a>
    </BaseSidebar>
  );
}

const ServerInfos = () => {
  const { t } = useTranslation();

  const [numberPlayers, setNumberPlayers] = useState(0);
  const [numberDinoz, setNumberDinoz] = useState(0);

  useEffect(() => {
      axios.get<number>(urlJoin(apiUrl, "utils", "numberplayers")).then(({ data }) => {
      setNumberPlayers(data);
    });

    axios.get<number>(urlJoin(apiUrl, "utils", "numberdinozs")).then(({ data }) => {
      setNumberDinoz(data);
    });
  }, []);

  return (
    <>
      <div className="textrose">
        {t("itIsLabel")}
        <Clock
          className="clock"
          timezone={"Europe/Paris"}
          format={"HH:mm:ss"}
          ticking={true}
        />{" "}
        {t("atDinolandLabel")}
      </div>
      <div className="textroseWP">
        {numberPlayers} {t("connectedLabel")}
      </div>
      <div className="textroseWP">
        {t("dinozActiveLabel")}
        {numberDinoz}
        {" Dinoz !)"}
      </div>
    </>
  );
};

export const HomeSidebar = () => {
  const { t } = useTranslation();

  return (
    <Sidebar>
      <SidebarSection title={t("dinoparcLabel")}>
        <SidebarLinks>
          <SidebarLink to="/">{t("presentationLabel")}</SidebarLink>
          <SidebarLink to="/inscription">{t("inscriptionLabel")}</SidebarLink>
        </SidebarLinks>
      </SidebarSection>
      <SidebarSection title={t("connexionLabel")}>
        <form action={urlJoin(apiUrl, "account", "redirect")} method={"POST"}>
          <input
            type="submit"
            value={t("seConnecterLabel") as string}
            className="Button"
          />
        </form>
      </SidebarSection>
    </Sidebar>
  );
};
