import React from "react";
import { MoonLoader } from "react-spinners";

export type LoaderProps = {
  size?: string | number | "small";
  css?: string;
};

export function Loader({ size, css }: LoaderProps) {
  const finalSize = size === "small" ? "24px" : size;
  return <MoonLoader color="#c37253" size={finalSize} css={css} />;
}
