import React, { HTMLAttributes } from "react";

import "./Row.css";

type Props = HTMLAttributes<HTMLDivElement> & {
  gap?: "normal" | "large" | "small" | "none";
  justify?:
    | "center"
    | "space-around"
    | "space-between"
    | "space-evenly"
    | "flex-start"
    | "flex-end";
  vcenter?: boolean;
  /** Please use Col components */
  children: React.ReactNode;
};

export default function Row({
  gap = "normal",
  justify,
  vcenter,
  children,
  className,
  ...attrs
}: Props) {
  let classArray = ["row"];
  if (gap !== "normal") {
    classArray.push(`is-gap-${gap}`);
  }
  if (vcenter) {
    classArray.push("is-vcenter");
  }
  if (justify) {
    classArray.push(`is-justify-${justify}`);
  }
  if (className) {
    classArray.push(className);
  }

  return (
    <div className={classArray.join(" ")} {...attrs}>
      {children}
    </div>
  );
}

type ColProps = HTMLAttributes<HTMLDivElement> & {
  grow?: boolean;
  vcenter?: boolean;
  children: React.ReactNode;
};

export const Col = ({
  grow,
  vcenter,
  children,
  className = "",
  ...attrs
}: ColProps) => {
  return (
    <div
      className={`col ${grow ? "is-grow " : ""}${
        vcenter ? " is-vcenter " : ""
      }${className}`.trim()}
      {...attrs}
    >
      {children}
    </div>
  );
};
