import React from "react";
import { useTranslation } from "react-i18next";
import "../assets/Home.css";
import starBannerShort from "../media/game/starBannerShort.png";
import eternalTwin from "../media/game/eternaltwin.png";
import Layout from "../containers/Layout";
import Section from "../components/Section";
import Row, { Col } from "../components/Row";
import Content from "../components/Content";

export default function Inscription() {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className="TEMP_mainContainer">
        <Section title={t("inscriptionLabel")}>
          <Row>
            <Col>
              <img alt="" src={starBannerShort} />
            </Col>
            <Col grow={true}>
              <Content>
                <p>{t("inscriptionNote")}</p>

                <a
                  href="https://eternal-twin.net/register"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="register to eternal-twin.net"
                    src={eternalTwin}
                    className="image"
                  />
                </a>

                <p>{t("inscriptionNoteBottom")}</p>
              </Content>
            </Col>
          </Row>
        </Section>
      </div>
    </Layout>
  );
}
