import React from "react";
import "../assets/Home.css";
import { useLocation } from 'react-router-dom';
import DinozCanvas from "./Game/shared/Dinoz";

export default function PublicCanvas () {
    const queryParams = new URLSearchParams(useLocation().search);

    return (
        <div className="canvasPage">
            <DinozCanvas appCode={queryParams.get('appCode')} size={parseInt(queryParams.get('size'))}></DinozCanvas>
        </div>
    );
};
