import i18n, { InitOptions } from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";
import translations from "./locales/translations/fr.json";
export const defaultNS = "translations";
export const resources = {
  fr: {
    translations,
  },
}; // as const; // TODO: enable this feature (auto type check)

const I18N_OPTIONS: InitOptions = {
  partialBundledLanguages: true,
  lng: localStorage.getItem("lang"),
  fallbackLng: "fr",
  ns: ["translations"],
  defaultNS,
  keySeparator: false,
  interpolation: {
    escapeValue: false, // TODO remove this config
    formatSeparator: ",",
  },
  resources,
};

const setDocumentLang = (newLang) =>
  document.documentElement.setAttribute("lang", newLang);

i18n
  .use(
    resourcesToBackend(
      (language, namespace) => import(`./locales/${namespace}/${language}.json`)
    )
  )
  .use(initReactI18next)
  .init(I18N_OPTIONS);

i18n.on("languageChanged", setDocumentLang);
setDocumentLang(i18n.language);

export default i18n;
