import React, { Suspense, useEffect, useState } from "react";
import "../../assets/Home.css";
import "../../assets/Game.css";
import { useTranslation } from "react-i18next";
import Store from "../../utils/Store";
import axios from "axios";
import { apiUrl } from "../../index";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import getLocationByNumber from "../utils/LocationByNumber";
import tinyGreenIcon from "../../media/dinoz/tiny_green.gif";
import tinyPurpleIcon from "../../media/dinoz/tiny_purple.gif";
import tinyYellowIcon from "../../media/dinoz/tiny_yellow.gif";
import buyIcon from "../../media/minis/buy.gif";
import helpIcon from "../../media/minis/help.gif";
import logoutIcon from "../../media/minis/logout.gif";
import ReactTooltip from "react-tooltip";
import Layout from "../Layout";
import Sidebar from "../Layout/Sidebar";
import { Dinoz as TDinoz } from "../../types/dinoz";
import { History as THistory } from "../../types/history";
import {
  SidebarLinks,
  SidebarLink,
  SidebarSection,
} from "../../components/Sidebar";
import CashAmount from "../../components/CashAmount";
import AsyncSection from "../../components/AsyncSection";
import {
  UserDinozListProvider,
  useUserDinozList,
} from "../../context/userDinozList";
import { useUserData } from "../../context/userData";
import { Loader } from "../../components/Loader";
import { useDebouncedLoading } from "../../hooks/useDebouncedLoading";
import urlJoin from "url-join";

const MyAccount = React.lazy(() => import("./section/MyAccount"));
const MyClan = React.lazy(() => import("./section/MyClan"));
const Dinoz = React.lazy(() => import("./section/Dinoz"));
const BuyADinoz = React.lazy(() => import("./section/BuyADinoz"));
const News = React.lazy(() => import("./section/News"));
const MyInventory = React.lazy(() => import("./section/MyInventory"));
const MyCollection = React.lazy(() => import("./section/MyCollection"));
const Shop = React.lazy(() => import("./section/Shop"));
const MyMessages = React.lazy(() => import("./section/MyMessages"));
const MyHistory = React.lazy(() => import("./section/MyHistory"));
const Pantheon = React.lazy(() => import("./section/Pantheon"));
const Rankings = React.lazy(() => import("./section/Rankings"));
const Clans = React.lazy(() => import("./section/Clans"));
const Help = React.lazy(() => import("./section/Help"));
const Player = React.lazy(() => import("./section/Player"));

const enum Section {
  Account = 1,
  MyClan,
  Dinoz,
  BuyADinoz,
  News,
  MyInventory,
  MyCollection,
  Shop,
  MyMessages,
  MyHistory,
  Pantheon,
  Rankings,
  Clans,
  Help,
  Player,
}

export default function GameContainer() {
  return (
    <UserDinozListProvider>
      <Game />
    </UserDinozListProvider>
  );
}

function Game() {
  const {
    accountId,
    refreshUser,
    cashAmount,
    refreshCashAmount,
    hasUnseenMessages,
    setHasUnseenMessages,
  } = useUserData();
  const {
    refreshList: refreshDinozList,
    selectedDinozId,
    setSelectedDinozId,
  } = useUserDinozList();

  let [refreshPage, setRefreshPage] = useState(false);
  const [preSelectedMailDestination, setPreSelectedMailDestination] = useState<
    undefined | { [id: string]: string }
  >();
  let [sectionCurrentlyActive, setSectionCurrentlyActive] = useState<Section>(
    Section.News
  );
  let store = Store.getInstance();
  let [currentDinozReload, setCurrentDinozReload] = useState(false);
  let [historyNotSeenCount, setHistoryNotSeenCount] = useState(0);
  let [reload, setReload] = useState(false);
  const [currentPlayerId, setCurrentPlayerId] = useState<string | null>(null);

  useEffect(() => {
    axios
      .get<THistory[]>(urlJoin(apiUrl, "account", accountId, "history", "unseen"))
      .then(({ data: history }) => {
        setHistoryNotSeenCount(
          history.filter((item) => item.seen === false).length
        );
      });
  }, [reload, accountId]);

  function refreshMenuPartial() {
    store = Store.getInstance();

    return Promise.all([
      // cash & dinoz count
      refreshUser(),
      refreshDinozList().then(() => {
        setSelectedDinozId(store.getSelectedDinoz());
      }),
    ]);
  }

  function refreshMenuAndGoToSection(section: Section) {
    refreshMenuPartial().then(() => {
      setSectionCurrentlyActive(section);
      window.scrollTo(0, 0);
    });
  }

  function refreshLocations() {
    refreshDinozList().then(() => {
      ReactTooltip.rebuild();
    });
  }

  function goToPlayerFiche(playerId: string) {
    setCurrentPlayerId(playerId);
    setSectionCurrentlyActive(Section.Player);
    window.scrollTo(0, 0);
  }

  function refreshHistoryNotificationsState() {
    axios
      .get<THistory[]>(urlJoin(apiUrl, "account", accountId, "/history", "unseen"))
      .then(({ data: history }) => {
        setHistoryNotSeenCount(
          history.filter((item) => item.seen === false).length
        );
      });
    refreshDinozList().then(() => {
      window.scrollTo(0, 0);
    });
  }

  function reloadMyAccountRuffleInstances() {
    reload = !reload;
    setReload(reload);
  }

  function unmarkNewMessages() {
    if (!hasUnseenMessages) {
      return;
    }
    axios
      .put(urlJoin(apiUrl, "account", accountId, "markMessages"))
      .then(({ data }) => {
        if (data) {
          setHasUnseenMessages(false);
        }
      });
  }

  function handlePlayerMessageSent({ name }: { name: string }) {
    setPreSelectedMailDestination({ [currentPlayerId]: name });
    setSectionCurrentlyActive(Section.MyMessages);
  }

  function setSectionCurrentlyActiveOrRefresh(section: Section) {
    if (sectionCurrentlyActive === section) {
      setReload(reload);
      setRefreshPage(refreshPage);
      setCurrentDinozReload(!currentDinozReload);
    }
    if (section === Section.MyMessages) {
      unmarkNewMessages();
    }
    if (section === Section.MyHistory) {
      setHistoryNotSeenCount(0);
    }
    setSectionCurrentlyActive(section);
    window.scrollTo(0, 0);
  }

  const sidebar = (
    <GameSidebar
      userCashAmount={cashAmount}
      selectedDinozId={
        sectionCurrentlyActive === Section.Dinoz ? selectedDinozId : null
      }
      onDinozSelect={(dinoz: TDinoz) => {
        setSelectedDinozId(dinoz.id);
        setSectionCurrentlyActiveOrRefresh(Section.Dinoz);
      }}
      onDinozRespawnAll={() => {
        if (sectionCurrentlyActive === Section.Dinoz) {
          // force a component refresh
          setSectionCurrentlyActiveOrRefresh(Section.Dinoz);
        }
      }}
      hasUnseenMessages={hasUnseenMessages}
      hasUnseenHistory={historyNotSeenCount !== 0}
      setSectionCurrentlyActive={setSectionCurrentlyActiveOrRefresh}
    />
  );

  return (
    <Layout sidebar={sidebar}>
      <Suspense fallback={<Loader css="margin: 2rem auto" />}>
        {sectionCurrentlyActive === Section.Account && (
          <MyAccount reload={reload} />
        )}
        {sectionCurrentlyActive === Section.MyClan && (
          <MyClan openPlayerProfile={goToPlayerFiche} />
        )}
        {sectionCurrentlyActive === Section.Dinoz && (
          <Dinoz
            dinozId={selectedDinozId}
            reloadCurrentDinoz={currentDinozReload}
            refreshCash={refreshCashAmount}
            refreshLocations={refreshLocations}
            refreshToShop={() => refreshMenuAndGoToSection(Section.Shop)}
            refreshHistory={refreshHistoryNotificationsState}
            refreshToInventory={() =>
              refreshMenuAndGoToSection(Section.MyInventory)
            }
            refreshToHistory={() =>
              refreshMenuAndGoToSection(Section.MyHistory)
            }
            refreshAll={reloadMyAccountRuffleInstances}
          />
        )}

        {sectionCurrentlyActive === Section.BuyADinoz && (
          <BuyADinoz
            callBack={() => refreshMenuAndGoToSection(Section.Dinoz)}
            refreshHistory={refreshHistoryNotificationsState}
          />
        )}
        {sectionCurrentlyActive === Section.News && <News />}
        {sectionCurrentlyActive === Section.MyInventory && (
          <MyInventory
            callBack={refreshLocations}
            refreshCash={refreshCashAmount}
          />
        )}
        {sectionCurrentlyActive === Section.MyCollection && <MyCollection />}
        {sectionCurrentlyActive === Section.Shop && (
          <Shop
            callBack={() => refreshMenuAndGoToSection(Section.MyInventory)}
            refreshHistory={refreshHistoryNotificationsState}
          />
        )}
        {sectionCurrentlyActive === Section.MyMessages && (
          <MyMessages
            preSelectedDestinationAccount={preSelectedMailDestination}
          />
        )}
        {sectionCurrentlyActive === Section.MyHistory && <MyHistory />}
        {sectionCurrentlyActive === Section.Rankings && (
          <Rankings
            openPlayerProfile={goToPlayerFiche}
            refreshPage={refreshPage}
          />
        )}
        {sectionCurrentlyActive === Section.Pantheon && <Pantheon />}
        {sectionCurrentlyActive === Section.Clans && (
          <Clans
            openPlayerProfile={goToPlayerFiche}
            refreshCash={refreshCashAmount}
            refreshPage={refreshPage}
          />
        )}
        {sectionCurrentlyActive === Section.Help && <Help />}
        {sectionCurrentlyActive === Section.Player && (
          <Player
            onMessage={handlePlayerMessageSent}
            playerId={currentPlayerId}
          />
        )}
      </Suspense>
    </Layout>
  );
}

type GameSidebarProps = {
  userCashAmount: number;
  selectedDinozId: null | string;
  onDinozSelect: (dinoz: TDinoz) => void;
  onDinozRespawnAll: () => void;
  hasUnseenMessages: boolean;
  hasUnseenHistory: boolean;
  setSectionCurrentlyActive: (SECTION) => void;
};

function GameSidebar({
  userCashAmount,
  selectedDinozId,
  onDinozSelect,
  onDinozRespawnAll,
  hasUnseenMessages,
  hasUnseenHistory,
  setSectionCurrentlyActive,
}: GameSidebarProps) {
  const { t } = useTranslation();
  const { logout } = useUserData();
  const store = Store.getInstance();
  const getSetSectionHandler = (section: Section) => () =>
    setSectionCurrentlyActive(section);

  return (
    <Sidebar>
      <SidebarSection title={store.getUsername()}>
        <SidebarLinks>
          <CashAmount amount={userCashAmount} />
          <SidebarLink onClick={getSetSectionHandler(Section.Account)}>
            {t("myAccountLabel")}
          </SidebarLink>
          <SidebarLink onClick={getSetSectionHandler(Section.MyClan)}>
            {t("myClanLabel")}
          </SidebarLink>
        </SidebarLinks>
      </SidebarSection>

      <DinozList
        selectedDinozId={selectedDinozId}
        onDinozSelect={onDinozSelect}
        onRespawnAll={onDinozRespawnAll}
        onBuyCLick={() => setSectionCurrentlyActive(Section.BuyADinoz)}
      />

      <SidebarSection title={t("menuLabel")}>
        <SidebarLinks>
          <SidebarLink onClick={getSetSectionHandler(Section.News)}>
            {t("newsLabel")}
          </SidebarLink>
          <SidebarLink onClick={getSetSectionHandler(Section.MyInventory)}>
            {t("inventoryLabel")}
          </SidebarLink>
          <SidebarLink onClick={getSetSectionHandler(Section.MyCollection)}>
            {t("collectionLabel")}
          </SidebarLink>
          <SidebarLink onClick={getSetSectionHandler(Section.Shop)}>
            {t("shopLabel")}
          </SidebarLink>
          <SidebarLink
            hasNotifications={hasUnseenMessages}
            onClick={getSetSectionHandler(Section.MyMessages)}
          >
            {t("messagesLabel")}
          </SidebarLink>

          <SidebarLink
            hasNotifications={hasUnseenHistory}
            onClick={getSetSectionHandler(Section.MyHistory)}
          >
            {t("historyLabel")}
          </SidebarLink>
          <SidebarLink onClick={getSetSectionHandler(Section.Rankings)}>
            {t("rankingsLabel")}
          </SidebarLink>
          <SidebarLink onClick={getSetSectionHandler(Section.Pantheon)}>
            {t("pantheonLabel")}
          </SidebarLink>
          <SidebarLink onClick={getSetSectionHandler(Section.Clans)}>
            {t("clansLabel")}
          </SidebarLink>
          <SidebarLink href="https://discord.gg/VR8bjWpvs4">
            {t("exchangeLabel")}
          </SidebarLink>

          <div className="smallBox"></div>

          <SidebarLink
            iconSrc={helpIcon}
            hasNotifications={true}
            onClick={getSetSectionHandler(Section.Help)}
          >
            {t("helpLabel")}
          </SidebarLink>
          <SidebarLink onClick={() => logout()} iconSrc={logoutIcon}>
            {t("disconnectLabel")}
          </SidebarLink>
        </SidebarLinks>
      </SidebarSection>
    </Sidebar>
  );
}

type DinozListProps = {
  selectedDinozId: null | string;
  onDinozSelect: (dinoz: TDinoz) => void;
  onRespawnAll: () => void;
  onBuyCLick: () => void;
};

const DinozList = ({
  selectedDinozId,
  onDinozSelect,
  onRespawnAll,
  onBuyCLick,
}: DinozListProps) => {
  const { t } = useTranslation();
  const { accountId } = useUserData();
  const {
    loading,
    list,
    refreshList,
    currentPage,
    total,
    goPreviousPage,
    goNextPage,
  } = useUserDinozList();
  const [dinozCollapsed, setDinozCollapsed] = useState(false);
  const debouncedLoading = useDebouncedLoading({ loading });

  const diedDinoz = list.filter((d) => d.life < 1);

  async function healAllDinoz() {
    if (diedDinoz.length > 0) {
      confirmAlert({
        overlayClassName: "neoparcConfirmOverlay",
        message: t("respawnAllDinoz.confirm"),
        buttons: [
          {
            label: t("respawnAllDinoz.confirm.no"),
            onClick: () => {},
          },
          {
            label: t("respawnAllDinoz.confirm.yes"),
            onClick: () => {
              axios
                .put(urlJoin(apiUrl, "account", accountId, "respawnAll"))
                .then(() => {
                  refreshList();
                  onRespawnAll();
                });
            },
          },
        ],
      });
    }
  }

  return (
    <>
      <div className="menuTitlePage">
        <button className="expandLinkPage" onClick={() => goPreviousPage()}>
          {"<"}
        </button>
        {t("pageLabel")}
        {"("}
        {currentPage + 1}
        {") "}
        <button className="expandLinkPage" onClick={() => goNextPage()}>
          {">"}
        </button>
      </div>

      <div className="menuTitleGame">
        <button
          className="expandLink"
          onClick={() => setDinozCollapsed(!dinozCollapsed)}
        >
          [{dinozCollapsed ? "+" : "-"}]
        </button>{" "}
        {t("vosDinozLabel")} ({total})
      </div>

      {!dinozCollapsed && (
        <AsyncSection loading={debouncedLoading}>
          <>
            {diedDinoz.length !== 0 && (
              <div className="hoverList" onClick={() => healAllDinoz()}>
                <img alt="" className="tinyDetailPurple" src={tinyYellowIcon} />
                <p className="pHeal">
                  <i>{t("respawnAllDinoz")}</i>
                </p>
                <p className="pDinozHeal">
                  <i>{t("respawnAllDinozMini")}</i>
                </p>
                <hr className="minilineHeal" />
              </div>
            )}

            {list.length !== 0 && (
              <div className="dinozFromList">
                {list.map((dinoz) => (
                  <DinozItem
                    key={dinoz.id}
                    dinoz={dinoz}
                    selected={selectedDinozId === dinoz.id}
                    onClick={() => onDinozSelect(dinoz)}
                  />
                ))}
              </div>
            )}
          </>
        </AsyncSection>
      )}

      <SidebarLink iconSrc={buyIcon} onClick={onBuyCLick}>
        {t("buyADinozLabel")}
      </SidebarLink>
    </>
  );
};

type DinozItemProps = {
  dinoz: TDinoz;
  selected?: boolean;
  onClick: () => void;
};

const DinozItem = ({ dinoz, selected, onClick }: DinozItemProps) => {
  const { t } = useTranslation();
  const isAlive = dinoz.life > 0;
  const canFight = isAlive && dinoz.actionsMap["Combat"] === true;
  const canMove = isAlive && dinoz.actionsMap["Deplacer"] === true;
  const hasLvlUp = dinoz.experience >= 100;
  const location = getLocationByNumber(dinoz.placeNumber);

  return (
    <div
      className={`hoverList${selected ? " hoverList--selected" : ""}`}
      onClick={onClick}
    >
      {canFight && (
        <img alt="" className="tinyDetailPurple" src={tinyPurpleIcon} />
      )}
      <p className={isAlive ? "pDinoz" : "pDinozDead"}>{dinoz.name}</p>
      {hasLvlUp && (
        <>
          <img alt="" className="tinyDetailGreen" src={tinyYellowIcon} />
          <p className="pDinozExp">{t("LevelUp")}</p>
        </>
      )}
      {canMove && (
        <img alt="" className="tinyDetailGreen" src={tinyGreenIcon} />
      )}
      <p className={isAlive ? "pDinozLieu" : "pDinozLieuDead"}>{location}</p>
      <hr className="minilineSeparation" />
    </div>
  );
};
