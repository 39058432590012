import React from "react";
import { useRef, useEffect } from "react";
import {canvas, renderDinozBySpriteDinoz} from "./helpers";

type Props = React.HTMLAttributes<HTMLDivElement> & {
  appCode: string;
  size?: number;
};

export default function DinozCanvas({ appCode, size, ...attrs }: Props) {
  const dinozCanvasRef = useRef(null);

  useEffect(() => {
    let canvas = dinozCanvasRef.current;
    let context = canvas.getContext("2d");
    context.clearRect(0, 0, size, size);
    renderDinozBySpriteDinoz(canvas, context, appCode);
  }, [appCode]);

  return  <canvas ref={dinozCanvasRef} width={size} height={size} />
}
