import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { apiUrl } from "..";
import Store from "../utils/Store";
import { useAuthRedirect } from "../hooks/useAuthRedirect";
import {Account, ActionMode, ImageMode} from "../types/account";
import urlJoin from "url-join";

type User = {
  userName: string;
  accountId: string;
  token: string;
};

type UserData = {
  loading: boolean;
  isAuthenticated: boolean;
  logout: () => Promise<boolean>;
  user: User | null;
  // setUser: (user:User) => void
  /** please do a refresh count of dinoz */
  refreshUser: () => Promise<void>;
  /** old totalPrice from store */
  cashAmount: number;
  refreshCashAmount: () => void;
  numberOfDinoz: number;
  setNumberOfDinoz: (numberOfDinoz: number) => void;
  imageMode: ImageMode;
  setImageMode: (imageMode: ImageMode) => void;
  actionMode: ActionMode;
  setActionMode: (actionMode: ActionMode) => void;

  hasUnseenMessages: boolean;
  setHasUnseenMessages: (boolean) => void;
};

export const UserDataContext = createContext<UserData>(null);

type Props = {
  children: React.ReactElement;
};

function fetchAccount(accountId: string) {
  return axios.get<Account>(urlJoin(apiUrl, "account", accountId));
}

function fetchCashAmount(accountId: string) {
  return axios.get<number>(urlJoin(apiUrl, "account", accountId, "money"));
}

function updateOldUserStore({ accountId, userName, token }: User) {
  const store = Store.getInstance();
  if (!accountId) {
    store.setAccountId(null);
    store.setUsername(null);
    store.setToken(null);
    store.setUserAuthenticated(false);
    return;
  }
  store.setAccountId(accountId);
  store.setUsername(userName);
  store.setToken(token);
  store.setUserAuthenticated(true);
}

function updateOldDataStore({
  cashAmount,
  imageMode,
  actionMode
}: {
  cashAmount?: number;
  imageMode?: ImageMode;
  actionMode?: ActionMode;
}) {
  const store = Store.getInstance();
  store.setTotalPrice(cashAmount || 0);
  store.setActionMode(actionMode || "STANDARD");
  store.setImgMode(imageMode || "RUFFLE");
}

export const UserDataProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(false);
  const [cashAmount, setCashAmount] = useState(0);
  const [numberOfDinoz, setNumberOfDinoz] = useState(0);
  const [imageMode, setImageMode] = useState<ImageMode>("RUFFLE");
  const [actionMode, setActionMode] = useState<ActionMode>("STANDARD");
  const [hasUnseenMessages, setHasUnseenMessages] = useState(false);
  const { authUser, doLogout } = useAuthRedirect();
  const user: User | null = authUser
    ? {
        accountId: authUser.userId,
        userName: authUser.userDisplayName,
        token: authUser.token,
      }
    : null;
  const isAuthenticated = authUser != null;

  const {
    userId: accountId,
    userDisplayName: userName,
    token,
  } = authUser || {};
  useEffect(() => {
    refreshUser();
  }, [accountId]);

  // TODO remove after store remove
  updateOldUserStore({ accountId, userName, token });
  useEffect(() => {
    updateOldUserStore({ accountId, userName, token });
  }, [accountId, userName, token]);
  updateOldDataStore({ imageMode, actionMode, cashAmount });
  useEffect(() => {
    updateOldDataStore({ imageMode, actionMode, cashAmount });
  }, [imageMode, actionMode, cashAmount]);

  function refreshUser() {
    if (!user) {
      // setUser(null);
      setCashAmount(0);
      setNumberOfDinoz(0);
      setImageMode("RUFFLE");
      setActionMode("STANDARD");
      setHasUnseenMessages(false);
      return;
    }

    setLoading(true);
    return fetchAccount(user.accountId).then(({ data }) => {
      setCashAmount(data.account.cash);
      setNumberOfDinoz(data.numberOfDinoz);
      setImageMode(data.account.imgMode || "RUFFLE");
      setActionMode(data.account.actionMode || "STANDARD");
      setHasUnseenMessages(data.account.messages !== false);
      setLoading(false);
    });
  }

  function refreshCashAmount() {
    if (!user) {
      return;
    }
    return fetchCashAmount(user.accountId).then(({ data }) => {
      setCashAmount(data);
    });
  }

  return (
    <UserDataContext.Provider
      value={{
        loading,
        isAuthenticated,
        logout: doLogout,
        user,
        // setUser,
        refreshUser,
        cashAmount,
        refreshCashAmount,
        numberOfDinoz,
        setNumberOfDinoz,
        imageMode,
        setImageMode,
        actionMode,
        setActionMode,
        hasUnseenMessages,
        setHasUnseenMessages,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => {
  const userDataContext = useContext(UserDataContext);

  const accountId = userDataContext.user?.accountId || null;
  // const logout = () => { userDataContext.setUser(null) }

  return {
    ...userDataContext,
    setImageMode(imageMode: ImageMode) {
      userDataContext.setImageMode(imageMode);
      return axios
        .patch(urlJoin(apiUrl, "player", accountId, "imgMode", imageMode))
        .then(() => {});
    },
    setActionMode(actionMode: ActionMode) {
      userDataContext.setActionMode(actionMode);
      return axios
        .patch(urlJoin(apiUrl, "player", accountId, "actionMode", actionMode))
        .then(() => {});
    },
    accountId,
  };
};
