import React, { MouseEventHandler } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./Sidebar.css";

type Props = {
  children: React.ReactNode;
};

export const Sidebar = ({ children }: Props) => {
  const { t } = useTranslation();
  return (
    <aside className="sidebar">
      <header className="header">{t("menuLabel")}</header>

      {children}
    </aside>
  );
};

type SidebarSectionProps = {
  title: React.ReactNode;
  children: React.ReactNode;
};

export const SidebarSection = ({ title, children }: SidebarSectionProps) => {
  return (
    <div className="sidebarSection">
      <div className="sidebarSection-title">{title}</div>
      {children}
    </div>
  );
};

type SidebarLinksProps = {
  children: React.ReactNode;
};

export const SidebarLinks = ({ children }: SidebarLinksProps) => {
  return <div className="sidebarLinks">{children}</div>;
};

type SidebarLinkProps = {
  iconSrc?: string;
  icon?: React.ReactNode;
  to?: string;
  href?: string;
  onClick?: MouseEventHandler;
  children: React.ReactNode;
  hasNotifications?: boolean;
};

export const SidebarLink = ({
  iconSrc,
  icon,
  children,
  to,
  href,
  onClick,
  hasNotifications,
}: SidebarLinkProps) => {
  const className = hasNotifications
    ? "sidebarLink sidebarLink--notify"
    : "sidebarLink";
  if (iconSrc) {
    icon = <img alt="" src={iconSrc} />;
  }

  return (
    <SidebarLinkContainer {...{ to, href, className, onClick }}>
      {icon}
      {children}
    </SidebarLinkContainer>
  );
};

type SidebarLinkContainerProps = {
  to?: string;
  href?: string;
  onClick?: MouseEventHandler;
  className?: string;
  children: React.ReactNode;
};

const SidebarLinkContainer = ({
  to,
  href,
  className,
  onClick,
  children,
}: SidebarLinkContainerProps) => {
  const linkProps = {
    className,
    onClick,
  };
  const anchorProps =
    href && href.startsWith("http")
      ? {
          target: "_blank",
          rel: "noopener noreferrer",
        }
      : {};

  return to ? (
    <Link to={to} {...linkProps}>
      {children}
    </Link>
  ) : href ? (
    <a href={href} {...{ ...linkProps, ...anchorProps }}>
      {children}
    </a>
  ) : (
    <button type="button" {...linkProps}>
      {children}
    </button>
  );
};
