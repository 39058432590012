import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./assets/Home.css";
import "./assets/scss/main.scss";
import Home from "./containers/Home";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Inscription from "./containers/Inscription";
import Game from "./containers/Game";
import ProtectedRoute from "./ProtectedRoute";
import urlJoin from "url-join";
import axios from "axios";
import "./i18n";
import Store from "./utils/Store";
import { UserDataProvider } from "./context/userData";
import { Loader } from "./components/Loader";
import PublicCanvas from "./containers/PublicCanvas";

export const API_SERVER = new URL(process.env.REACT_APP_API_SERVER);
export const apiUrl = urlJoin(API_SERVER.toString(), "api");
export const encryptionAES128Bits = "defaultEncryption";

axios.interceptors.request.use(
  (request) => {
    const token = Store.getInstance().getToken();
    if (token) {
      request.headers["Authorization"] = "Bearer " + token;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loader css="margin: 2rem auto" />}>
      <Router>
        <UserDataProvider>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route path="/inscription" component={Inscription}/>
                <Route path="/canvas" component={PublicCanvas}/>
                <ProtectedRoute path="/game" component={Game}/>
                <Redirect to="/"/>
            </Switch>
        </UserDataProvider>
      </Router>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
