import React from "react";

import "./CashAmount.css";

type CashAmountProps = {
  amount: number;
};

export default function CashAmount({ amount }: CashAmountProps) {
  const displayedAmount = amount.toLocaleString();

  return <span className="cashAmount">{displayedAmount}</span>;
}
