import React from "react";
import flagAngleterre from "../../media/minis/flag_angleterre.gif";
import flagFrance from "../../media/minis/flag_france.gif";
import flagEspagne from "../../media/minis/flag_espagne.gif";
import { useTranslation } from "react-i18next";

export default function Flags() {
  let { i18n } = useTranslation();

  function changeLanguageToEn() {
    localStorage.setItem("lang", "en");
    i18n.changeLanguage("en");
  }

  function changeLanguageToEs() {
    localStorage.setItem("lang", "es");
    i18n.changeLanguage("es");
  }

  function changeLanguageToFr() {
    localStorage.setItem("lang", "fr");
    i18n.changeLanguage("fr");
  }

  return (
    <div className="flags">
      <button className="flagpadding" onClick={changeLanguageToFr}>
        <img src={flagFrance} alt="fr" className="miniflags" />
      </button>
      <button className="flagpadding" onClick={changeLanguageToEs}>
        <img src={flagEspagne} alt="es" className="miniflags" />
      </button>
      <button className="flagpadding" onClick={changeLanguageToEn}>
        <img src={flagAngleterre} alt="en" className="miniflags" />
      </button>
    </div>
  );
}
