import React from "react";
import { Loader, LoaderProps } from "./Loader";

import "./AsyncSection.css";

type AsyncSectionProps = React.HTMLAttributes<HTMLDivElement> & {
  loading: boolean;
  loaderSize?: LoaderProps["size"];
  children: React.ReactElement;
};

export default function AsyncSection({
  loading,
  loaderSize,
  children,
  className = "",
  ...attrs
}: AsyncSectionProps) {
  return (
    <div className={`asyncSection ${className}`.trim()} {...attrs}>
      {children}
      {loading && (
        <div className="asyncSection-loader">
          <Loader size={loaderSize} />
        </div>
      )}
    </div>
  );
}
