import React, { JSXElementConstructor } from "react";
import { Redirect } from "react-router-dom";
import { useUserData } from "./context/userData";

export interface ProtectRouteProps {
  path: string;
  component: JSXElementConstructor<unknown>;
}

export default function ProtectedRoute({ component }: ProtectRouteProps) {
  const { isAuthenticated } = useUserData();
  const Component = component;
  return isAuthenticated ? <Component /> : <Redirect to={{ pathname: "/" }} />;
}
