import React from "react";
import "./Section.css";

type Props = Omit<React.HTMLAttributes<HTMLDivElement>, "title"> & {
  title?: React.ReactNode;
  children: React.ReactNode;
};

export default function Section({
  title,
  children,
  className = "",
  ...attrs
}: Props) {
  return (
    <div className={`section ${className}`.trim()} {...attrs}>
      {title && <header className="section-title">{title}</header>}
      {children}
    </div>
  );
}

export const SectionContentNoPadding = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className="sectionContent-noPadding">{children}</div>;
};
