import { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router";

type User = {
  userId: string;
  userDisplayName: string;
  token: string;
};

// TODO auto logout after token fail?
export const useAuthRedirect = () => {
  const [authUser, setAuthUser] = useState<User | null>(getUser());
  const isGameRoute = useRouteMatch("/game");
  const history = useHistory();

  const doLogout = () => {
    setAuthUser(null);
    window.localStorage.removeItem("user");
    return Promise.resolve(true);
  };

  useEffect(() => {
    const user = getUser();
    if (user && !getIsExpired(user.token)) {
      setAuthUser(user);
      window.localStorage.setItem("user", JSON.stringify(user));
      return;
    }

    doLogout();
  }, []);

  useEffect(() => {
    if (authUser && !isGameRoute) {
      history.push("/game");
    }
  }, [authUser]);

  function getTokenIsExpired() {
    return getIsExpired(authUser.token);
  }

  return { authUser, doLogout, getTokenIsExpired };
};

function getIsExpired(token: string) {
  const decoded = parseJwt(token);
  return !decoded || decoded.exp * 1000 <= Date.now();
}

const parseJwt = (token: string): { exp: number } | null => {
  try {
    return JSON.parse(window.atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

function getUser(): User | null {
  return getUserByUrl() || getUserLocalStorage() || null;
}

function getUserByUrl(): User | null {
  const curUrl = new URL(window.location.href);
  const userId = curUrl.searchParams.get("userId");
  const userDisplayName = curUrl.searchParams.get("userDisplayName");
  const checker = curUrl.searchParams.get("ac");
  const token = curUrl.searchParams.get("token");

  if (checker == null || window.atob(checker) !== userId + userDisplayName) {
    return null;
  }

  return {
    userId,
    userDisplayName,
    token,
  };
}

function getUserLocalStorage(): User | null {
  const userStored = window.localStorage.getItem("user");
  return userStored ? JSON.parse(userStored) : null;
}
