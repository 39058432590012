import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from "../containers/Layout";
import "../assets/Home.css";
import fight from "../media/game/fight.gif";
import fiche from "../media/game/fiche.gif";
import dinoland from "../media/game/dinoland.gif";
import dinoz from "../media/game/dinoz.jpg";
import SectionLight from "../components/SectionLight";
import Section from "../components/Section";
import Content from "../components/Content";
import "./Home.css";

export default function Home() {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className="TEMP_mainContainer">
        <Section title={t("presentationLabel")}>
          <Content>
            <p>{t("welcomeTextPart1")}</p>
          </Content>
          <SectionLight title={t("discoverDinoparc")}>
            <Chapter imageSrc={dinoland} text={t("welcomeTextPart2")} />
            <Chapter imageSrc={fiche} text={t("welcomeTextPart3")} />
            <Chapter imageSrc={fight} text={t("welcomeTextPart4")} />
          </SectionLight>

          <SectionLight title={t("andNow")}>
            <Content>
              <img alt="" src={dinoz} className="image" />
              <p>{t("welcomeTextPart5")}</p>
              <Link to="/inscription" className="links">
                {t("inscrivezVousNow")}
              </Link>
            </Content>
          </SectionLight>
        </Section>
      </div>
    </Layout>
  );
}

const Chapter = ({ text, imageSrc }: { text: string; imageSrc: string }) => {
  return (
    <div className="homeChapter">
      <img alt="" src={imageSrc} />
      <Content>
        <p>{text}</p>
      </Content>
    </div>
  );
};
